import React from 'react';
import '../../css/media.css';

const Media = () => {
    return (
        <div class="media-container">
            <h2>Coming Soon!</h2>
        </div>
    )
};

export default Media;